import axios from 'axios'
import * as mutations from '@/src/graphql/mutations'
const brandMap = {
  rates: 'RDC',
  ih: 'IH',
  lr: 'LR'
}
class ApiServiceLR {
  constructor (ctx) {
    this.ctx = ctx
    this.baseUrl = ctx.$config.LR_API_BASE_URL
  }

  getBaseUrlBySource (source) {
    console.log(this.ctx.$config)
    return this.ctx.$config[brandMap[source.toLowerCase()] ? `LR_API_BASE_URL_${brandMap[source.toLowerCase()]}` : 'LR_API_BASE_URL_IH']
  }

  async reassignLeadOutbound ({ id, lastHandledBy, callbackTime, source }) {
    try {
      const res = await axios.post(this.getBaseUrlBySource(source), {
        action: mutations.reassignLeadOutbound,
        params: {
          id, lastHandledBy, callbackTime
        }
      })
      return JSON.parse(res?.data?.reassignLeadOutbound)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async addLatestCallLogToOutbound ({ qrn, source, attemptFilter }) {
    try {
      const res = await axios.post(this.getBaseUrlBySource(source), {
        action: mutations.addLatestCallLogToOutbound,
        params: {
          qrn, source, attemptFilter
        }
      })
      return JSON.parse(res?.data?.addLatestCallLogToOutbound)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async changeOutboundLeadQrn ({ qrnUpdated, qrnOriginal, source }) {
    try {
      const res = await axios.post(this.getBaseUrlBySource(source), {
        action: mutations.changeOutboundLeadQrn,
        params: {
          qrnUpdated, qrnOriginal, source
        }
      })
      return JSON.parse(res?.data?.changeOutboundLeadQrn)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async closeOutboundLeads ({ qrn, source, quoter, leadId }) {
    try {
      const res = await axios.post(this.getBaseUrlBySource(source), {
        action: mutations.closeOutboundLeads,
        params: {
          qrn, source, quoter, leadId
        }
      })
      return JSON.parse(res?.data?.closeOutboundLeads)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async reinsertLeadToOutbound ({ qrn, source, callbackTime, lastHandledBy, attemptFilter }) {
    try {
      const res = await axios.post(this.getBaseUrlBySource(source), {
        action: mutations.reinsertLeadToOutbound,
        params: {
          qrn, source, callbackTime, lastHandledBy, attemptFilter
        }
      })
      return JSON.parse(res?.data?.reinsertLeadToOutbound)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async cancelOutboundCallLog ({ id, qrn, source, attempt }) {
    try {
      const res = await axios.post(this.getBaseUrlBySource(source), {
        action: mutations.cancelOutboundCallLog,
        params: {
          id, qrn, source, attempt
        }
      })
      return JSON.parse(res?.data?.cancelOutboundCallLog)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async removeLeadFromOutbound (payload) {
    try {
      const res = await axios.post(this.getBaseUrlBySource(payload.source), {
        action: mutations.removeLeadFromOutbound,
        params: {
          payload
        }
      })
      return JSON.parse(res?.data?.removeLeadFromOutbound)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async imFeelingLucky (searchQuery) {
    try {
      const res = await axios.post(this.getBaseUrlBySource(null), {
        action: mutations.imFeelingLucky,
        params: {
          searchQuery
        }
      })
      return JSON.parse(res?.data?.imFeelingLucky)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async syncLatestCallLogToOutbound ({ id, attempts, qrn, source }) {
    try {
      const res = await axios.post(this.getBaseUrlBySource(source), {
        action: mutations.syncLatestCallLogToOutbound,
        params: {
          id, attempts, qrn, source
        }
      })
      return JSON.parse(res?.data?.syncLatestCallLogToOutbound)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async syncAttemptsOutbound (qrn) {
    try {
      const res = await axios.post(this.getBaseUrlBySource(null), {
        action: mutations.syncAttemptsOutbound,
        params: {
          qrn
        }
      })
      return JSON.parse(res?.data?.syncAttemptsOutbound)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async reinsertFetchedByRcLeadsToOutbound () {
    try {
      const res = await axios.post(this.getBaseUrlBySource(null), {
        action: mutations.reinsertFetchedByRcLeadsToOutbound
      })
      return JSON.parse(res?.data?.reinsertFetchedByRcLeadsToOutbound)
    } catch (err) {
      console.error(err)
    }
  }

  errorHandler (err) {
    if ((typeof err === 'string' && err.match(/No current user|Cannot retrieve a new session/)) ||
        (typeof err === 'object' && err.code && err.code.match(/NotAuthorizedException/))) {
      // kick to login page
      this.ctx.store.dispatch('auth/logout')
      this.ctx.redirect({ name: `login___${this.ctx.app.i18n.locale}` })
    } else {
      // if err is not associated to session timeout, no user, etc. throw it back up to the caller
      return err
    }
  }
}

export default (context, inject) => {
  const apiService = new ApiServiceLR(context)
  inject('api_lr', apiService)
}
