import Vue from 'vue'

const defaultState = {
  lead: {},
  quoteSearch: {},
  outbound: {},
  transferSearch: {},
  pendingTransfer: null
}

export const state = () => ({
  ...defaultState
})

export const mutations = {
  SET_LEAD (s, lead) {
    s.lead = lead
  },
  CLEAR_LEAD (s) {
    s.lead = {}
  },
  SET_QUOTE_SEARCH (s, quoteSearch) {
    s.quoteSearch = quoteSearch
  },
  CLEAR_QUOTE_SEARCH (s) {
    s.quoteSearch = {}
  },
  SET_TRANSFER_SEARCH (s, transferSearch) {
    s.transferSearch = transferSearch
  },
  CLEAR_TRANSFER_SEARCH (s) {
    s.transferSearch = {}
  },
  SET_OUTBOUND (s, outbound) {
    s.outbound = outbound
  },
  CLEAR_OUTBOUND (s) {
    s.outbound = {}
  },
  SET_DEFAULT (s) {
    s = Object.assign({}, defaultState)
  },
  SET_PENDING_TRANSFER (s, id) {
    s.pendingTransfer = id
  },
  UPDATE_TRANSFER (s, transfer) {
    // eslint-disable-next-line
    const idx = s.lead.transfers.findIndex(el => el.transferId == transfer.transferId)
    if (idx > -1) {
      s.lead.transfers[idx] = Object.assign(s.lead.transfers[idx], transfer)
    }
  }
}

export const actions = {
  setLead ({ commit }, lead) {
    commit('SET_LEAD', lead)
  },
  clearLead ({ commit }) {
    commit('CLEAR_LEAD')
  },
  setQuoteSearch ({ commit }, quoteSearch) {
    commit('SET_QUOTE_SEARCH', quoteSearch)
  },
  clearQuoteSearch ({ commit }) {
    commit('CLEAR_QUOTE_SEARCH')
  },
  setTransferSearch ({ commit }, transferSearch) {
    commit('SET_TRANSFER_SEARCH', transferSearch)
  },
  clearTransferSearch ({ commit }) {
    commit('CLEAR_TRANSFER_SEARCH')
  },
  setOutbound ({ commit }, outbound) {
    commit('SET_OUTBOUND', outbound)
  },
  clearOutbound ({ commit }) {
    commit('CLEAR_OUTBOUND')
  },
  setDefault ({ commit }) {
    commit('SET_DEFAULT')
  },
  async postTransferActions ({ commit, state }, transfer) {
    try {
      const memo = transfer.memo ? [transfer.memo] : []
      commit('SET_PENDING_TRANSFER', transfer.transferId)

      //if (transfer.quoter === 'AUTO' && transfer.action === 'create' && !/caa/.test(transfer?.sourceForm)) {
        // Send TrustPilot email only when transfer was created (home handled within backend)
        //const actonResp = await sendDataToActon(transfer)
        //if (actonResp.status === 1) {
        //  memo.push('Successfully sent TrustPilot data to ActOn')
        //} else {
        //  memo.push(actonResp.error ? actonResp.error : 'Failed to send TrustPilot data to ActOn')
        //}
      //}

      // Send data to Qualtrics (current requirements only for auto)
      if (transfer.quoter === 'AUTO' && !/^(LRCAA)$/.test(transfer.quoteCategory) && !/caa/.test(transfer?.sourceForm)) {
        const qualtricsResp = await completeQualtricsQuoteEvent(transfer)
        memo.push(qualtricsResp?.status === 1 ? 'Successfully sent data to Qualtrics' : 'Failed to send data to Qualtrics')
      }

      const resp = await Vue.prototype.$api.updateTransfer({
        transferId: transfer.transferId,
        source: transfer.source,
        memo: memo.join('; ')
      })

      // Sync transfer changes on UI if main page is already loaded
      if (resp.status && state.lead?.cq_json?.QuoteInfo?.ID) {
        commit('UPDATE_TRANSFER', resp.transfer)
      }
    } catch (err) {
      console.error(err)
    }
    commit('SET_PENDING_TRANSFER', null)
  }
}

const completeQualtricsQuoteEvent = async (transfer) => {
  try {
    const data = {
      email: transfer.email.toLowerCase(),
      phone: transfer.phone,
      first_name: transfer.firstName,
      last_name: transfer.lastName,
      language: transfer.province === 'QC' ? 'FR' : 'EN',
      brand: transfer.source,
      event_id: 'COMPLETE_QUOTE',
      quote_ref_id: transfer.quoteRefId
    }
    return await Vue.prototype.$api.completeQualtricsQuoteEvent(data)
  } catch (err) {
    console.error(err)
  }
}

const sendDataToActon = async (transfer) => {
  try {
    const formId = `transfertrustpilot_${transfer.quoter.toLowerCase()}_${transfer.source.toLowerCase()}`
    const transferDate = Vue.prototype.$dayjs().format('YYYY-MM-DD')
    const trustPilotInvitationLink = await Vue.prototype.$api.getTrustPilotInvitationLink(transfer.source, {
      referenceId: transfer.quoteRefId,
      email: transfer.email,
      name: [transfer.firstName, transfer.lastName].join(' '),
      locale: transfer.province === 'QC' ? 'fr-FR' : 'en-CA',
      tags: [`${transfer.quoter}insurance`]
    })
    if (!trustPilotInvitationLink || !trustPilotInvitationLink.status) {
      return { status: 0, error: 'Failed to send transfer TrustPilot email - can not generate Invitation Link' }
    }

    const actonData = {
      'Client/EmailAddress': transfer.email,
      'Client/Name/FirstName': transfer.firstName,
      'Client/Name/LastName': transfer.lastName
    }

    if (transfer.quoter === 'AUTO' || transfer.quoter === 'IRCA') {
      actonData.AutoQuoteRefId = transfer.quoteRefId
      actonData.AutoTransferStatus = 'transferred'
      actonData.AutoTransferDate = transferDate
      actonData.AutoTransferCompany = transfer.carrier
      actonData.AutoTrustpilotUrl = trustPilotInvitationLink.url
    } else if (transfer.quoter === 'HOME' || transfer.quoter === 'COMMERCIAL_PROPERTY') {
      actonData.PropQuoteRefId = transfer.quoteRefId
      actonData.PropStatus = 'transferred'
      actonData.PropTransferDate = transferDate
      actonData.PropTrustpilotUrl = trustPilotInvitationLink.url
    }
    return await Vue.prototype.$api.sendDataToActOn(formId, JSON.stringify(actonData))
  } catch (err) {
    console.error(err)
  }
  return { status: 0, error: 'Failed to send transfer TrustPilot email.' }
}
